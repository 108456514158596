import React, { useState, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import ProductBox from '../../components/Product/Product';
import LayoutContext from '../../context/LayoutContext';
import { RelatedProduct } from '../../components/Product';
import {
  Container,
  Image,
  Info,
  Name,
  Item,
  Title,
  Content,
  CollapseStyled,
  Shop,
} from './StyledProduct';
import AddThis from '../../components/Product/AddThis';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Panel } from '../../components/Collapse';

import ShopIcon from '../../assets/images/product/shop.png';
import ShopIconHover from '../../assets/images/product/shop_hover.png';

const Product = ({ data }) => {
  const { t, i18n } = useTranslation();
  const { isMobileView } = useSelector((state) => state.common);
  const { remoteImage, content, related_products, categories } = data.product;
  const layoutProps = useContext(LayoutContext);
  const gender = layoutProps.pageContext.gender;
  const genderPath = gender === 'women' ? '/femme' : '';
  let [shopImg, setShopImg] = useState(ShopIcon);

  const {
    name,
    short_description,
    material,
    method,
    volume,
    price,
    meta_title,
    meta_desc,
    meta_keyword,
    hktvmall_link,
  } = content;

  const details = [
    {
      title: 'product_description',
      content: short_description,
    },
    {
      title: 'product_ingredients',
      content: material,
    },
    {
      title: 'product_application',
      content: method,
    },
    {
      title: 'product_volume',
      content: volume,
    },
    {
      title: 'product_price',
      content: price,
    },
  ];

  const category = categories[0].localizations[i18n.language].name;

  const onHover = () => {
    setShopImg(ShopIconHover)
  }

  const onOut = () => {
    setShopImg(ShopIcon)
  }

  const ViewProductResult = () => {
    const prices = price.split('/');
    let firstPrice = prices[0];
    firstPrice = firstPrice.replace('HK$', '').replace(',', '');
    firstPrice = parseInt(firstPrice, 10);

    const volumes = volume.split('/');
    let firstVolume = volumes[0];

    window.dataLayer.push({
      ecommerce: {
        detail: {
          actionField: {
            action: 'detail',
            list: 'list-result-range'
          },
          products: [
            {
              brand: 'BIOTHERM HK',
              category: category,
              id: data.product.slug,
              name: name,
              price: firstPrice,
              variant: firstVolume
            }
          ]
        }
      },
      event: 'nievent',
      event_name: 'view_item',
      eventAction: 'Product Detail',
      eventCategory: 'Ecommerce',
      eventLabel: `${name}::${data.product.slug}`,
      page_category: 'product detail page',
      site_language: i18n.language,
      brand: 'BIO',
      country: 'hk',
    });
  };

  useEffect(() => {
    ViewProductResult()
  }, []);

  const handleClicktoBuy = () => {
    const prices = price.split('/');
    let firstPrice = prices[0];
    firstPrice = firstPrice.replace('HK$', '').replace(',', '');
    firstPrice = parseInt(firstPrice, 10);

    const volumes = volume.split('/');
    let firstVolume = volumes[0];

    window.dataLayer.push({
      ecommerce: {
        add: {
          products: [
            {
              brand: 'BIOTHERM HK',
              category: category,
              id: data.product.slug,
              name: name,
              price: firstPrice,
              quantity: 1,
              variant: firstVolume
            }
          ]
        }
      },
      event: 'addToCart',
      event_name: 'add_to_cart',
      eventAction: `Shop_Button`,
      eventCategory: 'Ecommerce',
      eventLabel: `${name}::${data.product.slug}`,
      product_info: `${name}::${data.product.slug}`,
      step: 'redirection',
      store_name: 'BACC',
      page_category: 'product listing page',
      site_language: i18n.language,
      brand: 'BIO',
      country: 'hk',
      ua_eventAction:'BACC',
    });
  };

  return (
    <Layout
      fullwidth={isMobileView}
      seoSettings={{
        title: meta_title === null ? name : meta_title,
        description:
          meta_desc === null ? `${short_description}` : `${meta_desc}`,
        keywords: meta_keyword === null ? meta_keyword : '',
        image: remoteImage.childImageSharp.fluid.src,
      }}
      breadcrumb={['b_product', name]}
    >
      <div id="category" style={{ display: 'none' }}>{category}</div>
      {isMobileView ? (
        <Container>
          <PageTitle>{name}</PageTitle>
          <Image>
            <Img fluid={remoteImage.childImageSharp.fluid} alt={name} />
          </Image>
          <Shop>{t('product_shop')}<a href={hktvmall_link}></a></Shop>
          <CollapseStyled>
            <Panel header={t('product_mb_description')} panelKey={1}>
              <p dangerouslySetInnerHTML={{ __html: short_description }} />
              <br />
              <br />
              <p
                dangerouslySetInnerHTML={{ __html: t('product_volume') }}
              />{' '}
              <p dangerouslySetInnerHTML={{ __html: volume }} />
              <br />
              <p
                dangerouslySetInnerHTML={{ __html: t('product_price') }}
              />{' '}
              <p dangerouslySetInnerHTML={{ __html: price }} />
            </Panel>
            <Panel header={t('ingredients_and_application')} panelKey={2}>
              <p
                dangerouslySetInnerHTML={{ __html: t('product_ingredients') }}
              />
              :
              <p dangerouslySetInnerHTML={{ __html: material }} />
              <p
                dangerouslySetInnerHTML={{ __html: t('product_application') }}
              />
              :
              <p dangerouslySetInnerHTML={{ __html: method }} />
            </Panel>

            {related_products.length > 0 && (
              <Panel header={t('perfect_partners')} panelKey={3}>
                {related_products.map((item) => {
                  return (
                    <ProductBox
                      key={item.id}
                      title={item.content.name}
                      description={short_description}
                      image={item.remoteImage.childImageSharp.fluid}
                      path={`${genderPath}/product/${item.slug}`}
                    />
                  );
                })}
              </Panel>
            )}
          </CollapseStyled>
        </Container>
      ) : (
        <>
          <Container className={`row no-gutters`}>
            <Image>
              <Img fluid={remoteImage.childImageSharp.fluid} alt={name} />
              {/* {!isMobileView && <AddThis />} */}
            </Image>
            <Info>
              <Name>{name}</Name>
              {details.map((detail, index) => {
                return (
                  <Item className={`row no-gutters`} key={index}>
                    <Title
                      dangerouslySetInnerHTML={{ __html: t(detail.title) }}
                    />
                    <Content
                      dangerouslySetInnerHTML={{ __html: detail.content }}
                    />
                  </Item>
                );
              })}
              {hktvmall_link &&
                <Shop onMouseOver={() => onHover()} onMouseOut={() => onOut()} onClick={() => handleClicktoBuy()}><img src={shopImg} alt="shop" />{t('product_shop')}<a href={hktvmall_link} target='_blank'></a></Shop>
              }
            </Info>
          </Container>

          {related_products.length > 0 && (
            <RelatedProduct items={related_products} />
          )}
        </>
      )}
    </Layout>
  );
};

export default Product;

export const pageQuery = graphql`
  query($slug: String, $locale: String) {
    product(slug: { eq: $slug }) {
      id
      slug
      content(locale: $locale) {
        name
        short_description
        material
        method
        volume
        price
        meta_title
        meta_desc
        meta_keyword
        hktvmall_link
      }
      categories{
        localizations {
          en {
            name
          }
          zh {
            name
          }
        }
      }
      remoteImage {
        childImageSharp {
          fluid(quality: 90, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      related_products {
        id
        slug
        content(locale: $locale) {
          name
          short_description
        }
        remoteImage {
          childImageSharp {
            fluid(quality: 100, maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
